<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="section-form-content">
          <div class="block-content">
            <div v-if="listRecruitment.length > 0">
              <table>
                <tr>
                  <th>#</th>
                  <th>ຜູ້ຂໍພະນັກງານ</th>
                  <th>ຊື່ຕຳແໜ່ງ</th>
                  <th>ຈຳນວນຕຳແໜ່ງ</th>
                  <th>ພະແນກ/ໜ່ວຍງານ</th>
                  <th>ຜູ້ກວດສອບ</th>
                  <th>ເວລາ</th>
                </tr>
                <tbody class="lfont custom_table">
                  <tr v-for="(item, i) in listRecruitment">
                    <td>
                      <v-btn icon color="blue" @click="detail(item.id)">
                        <v-icon>mdi-eye-circle</v-icon>
                      </v-btn>
                      <!-- <v-btn class="mx-2 lfont" dark small color="warning" @click="edit_data(item.id)">
                        <v-icon dark>mdi-pencil</v-icon> ແກ້ໄຂ
                      </v-btn> -->
                    </td>
                    <td>{{ item.requester.name }} {{ item.requester.surname }}</td>
                    <td class="text-primary">{{ item.position.name }}</td>
                    <td>{{ item.need_qty }}</td>
                    <td>{{ item.working_at_department.name }}</td>
                    <td>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(approver_item, k) in item.approver" :key="k">
                          <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ approver_item.name }} {{ approver_item.surname }} ({{
                                $t(`recruitment.${approver_item.position}`) }})
                              <v-icon dense color="success"
                                v-if="approver_item.status == 'approved'">mdi-check-circle</v-icon>
                              <v-icon dense color="red"
                                v-else-if="approver_item.status == 'reject'">mdi-close-circle</v-icon>
                              <v-icon dense v-else>mdi-alert-circle</v-icon>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </td>
                    <td class="text-danger">{{ item.created }}</td>
                  </tr>
                </tbody>
              </table>

              <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
                @paginate="fetchRecruitmentRequest">
              </Pagination>
            </div>
            <defaultTableNoResult v-else />
            <Loading v-if="isLoading" />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      dialog: false,
      isLoading: false,
      id: "",
      listRecruitment: [],
    };
  },
  methods: {
    edit_data(id) {
      this.$router
        .push({
          name: "recruitment.edit",
          params: {
            id: id,
          },
        })
        .catch(() => { });
    },
    detail(id) {
      this.$router
        .push({
          name: "director.approve.detail",
          params: {
            id: id,
          },
        })
        .catch(() => { });
    },
    onCreate() {
      this.$router
        .push({
          name: "recruitment.add",
        })
        .catch(() => { });
    },
    fetchRecruitmentRequest() {
      this.isLoading = true;
      this.$axios
        .get(`/employee/director/approve/recruitment`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.listRecruitment = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listRecruitment.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchRecruitmentRequest();
  },
};
</script>

<style lang="scss" scoped>
.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}
</style>
